import React from 'react'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import IconButton from '@material-ui/core/IconButton'
import MenuIcon from '@material-ui/icons/Menu'
import Drawer from '@material-ui/core/Drawer'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import { isLoaded, isEmpty } from 'react-redux-firebase/lib/helpers'
import AccountMenu from './AccountMenu'
import styles from './Navbar.styles'
import List from '@material-ui/core/List'
import Divider from '@material-ui/core/Divider'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import AddIcon from '@material-ui/icons/AddCircleOutline'
import SearchIcon from '@material-ui/icons/Search'

import {
  HOME_PATH,
  LOGIN_PATH,
  LIST_REQUESTS_PATH,
  NEW_REQUEST_PATH
} from 'constants/paths'

const useStyles = makeStyles(styles)

function Navbar({ history, firebase, auth, profile }) {
  const classes = useStyles()
  const theme = useTheme()
  const authExists = isLoaded(auth) && !isEmpty(auth)

  const [open, setOpen] = React.useState(false)

  const handleDrawerOpen = () => {
    setOpen(true)
  }

  const handleDrawerClose = () => {
    setOpen(false)
  }

  return (
    <div>
      <AppBar position="static" className={classes.appBar}>
        <Toolbar>
          {authExists ? (
            <IconButton
              edge="start"
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              className={clsx(classes.menuButton, open && classes.hide)}>
              <MenuIcon />
            </IconButton>
          ) : (
            <div></div>
          )}
          <Typography
            color="inherit"
            variant="h6"
            component={Link}
            to={authExists ? HOME_PATH : '/'}
            className={classes.brand}
            data-test="brand">
            SIMBS Containers POs
          </Typography>
          <div className={classes.flex} />
          {authExists ? (
            <AccountMenu />
          ) : (
            <Button
              className={classes.signIn}
              component={Link}
              to={LOGIN_PATH}
              data-test="sign-in">
              Sign In
            </Button>
          )}
        </Toolbar>
      </AppBar>
      <Drawer
        className={classes.drawer}
        variant="temporary"
        anchor="left"
        open={open}
        onClose={handleDrawerClose}
        classes={{
          paper: classes.drawerPaper
        }}>
        <div className={classes.drawerHeader}>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'ltr' ? (
              <ChevronLeftIcon />
            ) : (
              <ChevronRightIcon />
            )}
          </IconButton>
        </div>
        <Divider />
        <List>
          <ListItem
            button
            key="New Request"
            component={Link}
            to={NEW_REQUEST_PATH}>
            <ListItemIcon>
              <AddIcon />
            </ListItemIcon>
            <ListItemText primary="New Request" />
          </ListItem>
          <ListItem
            button
            key="Search Request"
            component={Link}
            to={LIST_REQUESTS_PATH}>
            <ListItemIcon>
              <SearchIcon />
            </ListItemIcon>
            <ListItemText primary="Search Request" />
          </ListItem>
        </List>
        <Divider />
      </Drawer>
    </div>
  )
}

Navbar.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired // from enhancer (withRouter)
  }),
  firebase: PropTypes.shape({
    logout: PropTypes.func.isRequired // from enhancer (withFirebase)
  }),
  profile: PropTypes.shape({
    displayName: PropTypes.string, // from enhancer (connect)
    avatarUrl: PropTypes.string // from enhancer (connect)
  })
}

export default Navbar
