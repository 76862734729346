import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Card from '@material-ui/core/Card'
import CardActions from '@material-ui/core/CardActions'
import CardContent from '@material-ui/core/CardContent'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import AddIcon from '@material-ui/icons/AddCircleOutline'
import SearchIcon from '@material-ui/icons/Search'
import Container from '@material-ui/core/Container'
import styles from './HomePage.styles'
import { LIST_REQUESTS_PATH, NEW_REQUEST_PATH } from 'constants/paths'

const useStyles = makeStyles(styles)

function Home(profile) {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <br />
      <Typography variant="h5" component="h3">
        Welcome!
      </Typography>
      <Typography component="p" className={classes.description}>
        Below you'll find the options that will help us to perform and track
        requests related to the containers you will submit or already submitted.
      </Typography>
      <br />
      <Container maxWidth="lg">
        <Grid
          container
          justify="center"
          alignItems="center"
          spacing={4}
          className={classes.grid}>
          <Grid item xs={12} sm={6}>
            <Card className={classes.card}>
              <CardContent>
                <Typography gutterBottom variant="h5" component="h5">
                  <AddIcon /> New Request
                </Typography>
                <Typography variant="body2" color="textSecondary" component="p">
                  Submit a new containers request so that our team can start
                  tracking it.
                </Typography>
              </CardContent>
              <CardActions className={classes.buttonArea}>
                <Button
                  variant="contained"
                  size="large"
                  color="primary"
                  className={classes.button}
                  href={NEW_REQUEST_PATH}>
                  Create
                </Button>
              </CardActions>
            </Card>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Card className={classes.card}>
              <CardContent>
                <Typography gutterBottom variant="h5" component="h5">
                  <SearchIcon /> Search a Request
                </Typography>
                <Typography variant="body2" color="textSecondary" component="p">
                  Are you interested in reviewing a request already submitted?
                </Typography>
              </CardContent>
              <CardActions className={classes.buttonArea}>
                <Button
                  variant="contained"
                  size="large"
                  color="primary"
                  className={classes.button}
                  href={LIST_REQUESTS_PATH}>
                  Search
                </Button>
              </CardActions>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </div>
  )
}

export default Home
