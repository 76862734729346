export const HOME_PATH = '/'
export const LIST_PATH = '/projects'
export const ACCOUNT_PATH = '/account'
export const LOGIN_PATH = '/login'
export const SIGNUP_PATH = '/signup'
export const FORGOT_PWD_PATH = '/ForgotPassword'
// Container Request
export const LIST_REQUESTS_PATH = '/requests'
export const NEW_REQUEST_PATH = '/request/new'
export const EDIT_REQUEST_PATH = '/requests/edit'
export const VIEW_REQUEST_PATH = '/requests/view'
