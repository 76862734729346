import { Loadable } from 'utils/components'
import { FORGOT_PWD_PATH as path } from 'constants/paths'

export default {
  path,
  component: Loadable({
    loader: () =>
      import(/* webpackChunkName: 'ForgotPassword' */ './components/ForgotPasswordPage')
  })
}
