import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import styles from './Jumbotron.styles'
import Container from '@material-ui/core/Container'

const useStyles = makeStyles(styles)

function Jumbotron({ jumbotron }) {
  const classes = useStyles()

  return (
    <Container maxWidth="xl" className={classes.root}>
      <div className={classes.h1}>Get there!</div>
      <div className={classes.p}>Containers Form Request</div>
    </Container>
  )
}

Jumbotron.propTypes = {
  jumbotron: PropTypes.object // from enhancer (firestoreConnect + connect)
}

export default Jumbotron
