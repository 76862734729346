import bgImageUrl from 'static/port-containers.jpg'

export default theme => ({
  root: {
    padding: 0,
    backgroundImage: `url(${bgImageUrl})`,
    backgroundPosition: 'bottom',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundAttachment: 'fixed',
    height: '200px',
    position: 'relative'
  },
  h1: {
    color: '#FFFFFF',
    paddingTop: '50px',
    marginLeft: '40px',
    fontSize: '30px'
  },
  p: {
    color: '#FFFFFF',
    paddingTop: '10px',
    marginLeft: '40px'
  }
})
