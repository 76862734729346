export default theme => ({
  root: {
    flexGrow: 1,
    margin: theme.spacing(2)
  },
  grid: {
    padding: theme.spacing(2)
  },
  description: {
    margin: theme.spacing(1, 0, 4, 0)
  },
  buttonArea: {
    textAlign: 'center',
    display: 'block',
    marginBottom: 8
  },
  button: {
    width: '100%'
  },
  card: {
    minWidth: 225
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)'
  },
  title: {
    fontSize: 14
  },
  pos: {
    marginBottom: 12
  }
})
